import React, { useState, useEffect, useContext } from 'react'
import { Link as LinkComponent } from 'gatsby'
import styled from 'styled-components'
import media from 'styled-media-query'

import Layout, { TopBar, Footer } from '@/components/Layout'

import { white, orange, black } from '@/style-guide/colors'

import ArticleContext from '@/context/ArticleContext'

import Section from './Section'

const Container = styled.div`
  background-color: #f7f7f7;
  width: 100%;
`

const Header = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(${({ image }) => image});
  height: 407px;
  margin: 0px 25px 100px 25px;
  position: relative;
  top: 100px;

  ${media.greaterThan('medium')`
    margin: 0px 157px 100px 157px;
  `}
`

const Content = styled.div`
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 25px;

  ${media.greaterThan('medium')`
    margin-top: 0px;
    flex-direction: row;
    padding: 40px 157px;
  `}
`

const BackToBlog = styled.div`
  width: 228px;
  height: 60px;
  background-color: ${white};
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  > span {
    color: ${orange};
    font-weight: bold;
    font-size: 14px;
    line-height: 46px;
  }

  ${media.greaterThan('medium')`
    margin: 30px 157px 0px 157px;
  `}
`

const Link = styled(LinkComponent)`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.greaterThan('medium')`
    justify-content: flex-start;
  `}
`

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 300px;
`

const Text = styled.span`
  font-size: 14px;
  line-height: 46px;
  color: ${black};
`

const ArticlePage = () => {
  const articleContext = useContext(ArticleContext)
  const [article, setArticle] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetch(`https://cms-calculatex.herokuapp.com/articles/${articleContext.articleId}`)
      .then(response => response.json())
      .then(article => {
        setArticle(article)
        setLoading(false)
      })
  }, [articleContext.articleId])

  let content = (
    <MessageContainer>
      <Text>Something went wrong. Please, go back to blog and try again.</Text>
      <Link to="/blog">
        <BackToBlog>
          <span>Back to blog</span>
        </BackToBlog>
      </Link>
    </MessageContainer>
  )

  if (loading) {
    content = (
      <MessageContainer>
        <Text>Loading article...</Text>
      </MessageContainer>
    )
  } else if (article._id) {
    content = (
      <>
        <Header image={article.image?.url} />
        <Content>
          <Section
            title={article.title}
            text={article.text}
            author={article.author?.name}
            avatar={article.author?.avatar.url}
            minutesRead={article.minutesRead}
          />
        </Content>
        <Link to="/blog">
          <BackToBlog>
            <span>Back to blog</span>
          </BackToBlog>
        </Link>
      </>
    )
  }

  return (
    <Layout>
      <Container>
        <TopBar />
        {content}
        <Footer />
      </Container>
    </Layout>
  )
}

export default ArticlePage
