import React from 'react'
import { string, number } from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'

import ArticleInfo from '@/components/ArticlePreview/ArticleInfo'

import facebook from '@/assets/icons/facebook-small.svg'
import google from '@/assets/icons/googleplus-small.svg'
import twitter from '@/assets/icons/twitter-small.svg'
import linkedin from '@/assets/icons/linkedin-small.svg'

import { grey, black } from '@/style-guide/colors'

const Container = styled.div`
  width: 100%;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${media.greaterThan('medium')`
    flex-direction: row;
  `}
`

const Label = styled.div`
  font-style: italic;
  font-size: 14px;
  line-height: 24px;
`

const Socials = styled.div`
  margin: 0px 20px;
`

const Shares = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  color: ${grey};

  ${media.greaterThan('medium')`
    justify-content: flex-end;
    margin-top: 0px;
  `}
`

const Img = styled.img`
  cursor: pointer;
  margin-right: 5px;
`

const Title = styled.span`
  font-weight: bold;
  font-size: 36.3969px;
  line-height: 46px;
  color: ${black};
  margin-top: 35px;
`

const Text = styled.span`
  color: ${grey};
  font-style: italic;
  font-size: 14px;
  line-height: 24px;
  margin-top: 20px;
  white-space: pre-wrap;

  p {
    margin-bottom: 25px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
`

const Section = ({ title, text, author, avatar, minutesRead }) => (
  <Container>
    <Header>
      <ArticleInfo
        styleText={{ color: '#7F92A3' }}
        style={{ width: 280 }}
        avatar={avatar}
        author={author}
        minutesRead={minutesRead}
      />
      <Shares>
        <Label>Share</Label>
        <Socials>
          <Img src={facebook} alt="Facebook" />
          <Img src={google} alt="Google+" />
          <Img src={linkedin} alt="LinkedIn" />
          <Img src={twitter} alt="Twitter" />
        </Socials>
      </Shares>
    </Header>
    <Content>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </Content>
  </Container>
)

Section.propTypes = {
  title: string,
  text: string,
  avatar: string,
  author: string,
  minutesRead: number
}

export default Section
